import React, { Component } from 'react'

import './topic-list.styl'

class TopicList extends Component {
  render(): JSX.Element {
    const { children } = this.props

    return <div className="topic-list">{children}</div>
  }
}

export default TopicList
