import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './expandable-text.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import Linka from 'client/shared/blocks/linka'
import SimpleFormat from 'client/shared/blocks/simple-format'
import Spacer from 'client/shared/blocks/spacer'

type Props = {
  text: string
  maxHeight: number
  path?: string
  blurColor: string
  bookLang?: string
  hideBlur?: boolean
} & DecoratorProps

type State = {
  canBeExpanded: boolean
  isExpanded: boolean
}

class ExpandableText extends Component<Props, State> {
  static defaultProps = {
    text: '',
    maxHeight: 192, // font-size (16) * line-height (1.5) * number of rows (8)
    blurColor: '#F4F2EF', // $base-default
  }

  state = {
    canBeExpanded: false,
    isExpanded: false,
  }

  textContainer: HTMLDivElement | null = null

  componentDidMount(): void {
    if (this.textContainer) {
      this.setState({
        canBeExpanded: this.textContainer.clientHeight > this.props.maxHeight,
      })
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { canBeExpanded: prevCanBeExpanded } = prevState

    if (this.textContainer) {
      const canBeExpanded =
        this.textContainer.clientHeight > this.props.maxHeight

      if (prevCanBeExpanded !== canBeExpanded) {
        this.setState({ canBeExpanded })
      }
    }
  }

  get shouldCutText(): boolean {
    const { isExpanded, canBeExpanded } = this.state

    return !isExpanded && canBeExpanded
  }

  showMore = (): void => {
    this.setState({
      isExpanded: true,
    })
  }

  showLess = (): void => {
    this.setState({
      isExpanded: false,
    })
  }

  render(): JSX.Element | null {
    const {
      text,
      maxHeight,
      blurColor,
      locale,
      bookLang,
      hideBlur,
    } = this.props

    if (!text) return null

    const cutMod = this.shouldCutText ? 'expandable-text__text_cut' : ''
    const lang = locale !== bookLang ? { lang: bookLang } : {}

    return (
      <div
        className="expandable-text"
        ref={element => (this.textContainer = element)}
      >
        <div
          {...lang}
          className={`expandable-text__text ${cutMod}`}
          style={{ maxHeight: this.shouldCutText ? maxHeight : 'initial' }}
        >
          {text && <SimpleFormat text={text} withLinks />}
          {this.shouldCutText && !hideBlur && (
            <div
              className="expandable-text__blur"
              style={{
                backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), ${blurColor} 90%)`,
              }}
            />
          )}
        </div>
        {text && this.state.canBeExpanded && this.renderButton()}
        <Spacer size={8} />
      </div>
    )
  }

  renderButton(): JSX.Element {
    const { isExpanded } = this.state
    const { path } = this.props

    if (path) {
      return (
        <Linka path={path} className="expandable-text__button" target="_blank">
          <Trans id="book.more" />
        </Linka>
      )
    } else {
      return (
        <div
          className="expandable-text__button"
          onClick={isExpanded ? this.showLess : this.showMore}
        >
          <Trans id={isExpanded ? 'book.less' : 'book.more'} />
        </div>
      )
    }
  }
}

export default uiBox(ExpandableText)
