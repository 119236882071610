import React from 'react'

import './quote.styl'

type Props = {
  children: React.ReactNode
}

function Quote({ children }: Props) {
  return <div className="quote">{children}</div>
}

export default Quote
