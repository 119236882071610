import React, { Component } from 'react'

import urlFor, { QueryParams } from 'shared/tools/url-helper'
import Linka from 'client/shared/blocks/linka'

import { ResourceTopicProps } from 'client/shared/types/topic'
import type { Exact } from 'types/local/exact-check'

import './topic.styl'

type Props = Exact<ResourceTopicProps> & {
  path?: string
  resource: string
  query?: QueryParams
}

function getTopicPath({ resource, parent_slug, slug, is_subtopic, query }) {
  const topicHierarchy = urlFor.topicHierarchy({
    resourceType: resource,
    topic: is_subtopic && parent_slug ? parent_slug : slug,
    subtopic: is_subtopic ? slug : undefined,
    query,
  })

  return is_subtopic ? topicHierarchy.subtopic : topicHierarchy.all
}

class Topic extends Component<Props> {
  render(): JSX.Element {
    const {
      title,
      path,
      resource,
      slug,
      is_subtopic,
      parent_slug,
      query,
    } = this.props

    const topicPath =
      path || getTopicPath({ resource, parent_slug, slug, is_subtopic, query })

    return (
      <Linka path={topicPath} className="topic">
        {title}
      </Linka>
    )
  }
}

export default Topic
