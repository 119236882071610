import React, { Component } from 'react'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import urlFor, { QueryParams } from 'shared/tools/url-helper'

import AuthorLanguageVariant from 'client/bookmate/blocks/author-language-variant'

import { Dropdown } from 'client/shared/blocks/dropdown'
import Linka from 'client/shared/blocks/linka'

import { AuthorProps } from 'client/shared/types/author'
import { AuthorLanguageVariant as AuthorLanguageVariantType } from 'client/bookmate/reducers/author-reducer'

import './author-language-switcher.styl'

type Props = {
  author: AuthorProps
  languageVariants: AuthorLanguageVariantType[]
  query?: QueryParams
} & DecoratorProps

type State = {
  dropdownShown: boolean
}

const DROPDOWN_TOP_OFFSET = 20

class AuthorLanguageSwitcher extends Component<Props, State> {
  state = {
    dropdownShown: false,
  }

  componentDidUpdate(prevProps) {
    const currentAuthorId = this.props.author.uuid
    const prevAuthorId = prevProps.author.uuid

    if (prevAuthorId !== currentAuthorId) {
      this.closeDropdown()
    }
  }

  get filteredLanguageVariants(): AuthorLanguageVariantType[] {
    const {
      author: { uuid: currentAuthorId },
      languageVariants,
    } = this.props

    // exclude language variant of the current author
    const filteredLanguageVariants = languageVariants
      ?.filter(({ uuid }) => uuid !== currentAuthorId)
      ?.sort((a, b) => b.works_count - a.works_count)

    return filteredLanguageVariants
  }

  toggleDropdown = (e: Event) => {
    e.preventDefault()
    this.setState({ dropdownShown: !this.state.dropdownShown })
  }

  closeDropdown = () => {
    this.setState({ dropdownShown: false })
  }

  render(): JSX.Element | null {
    if (!this.filteredLanguageVariants.length) return null

    const {
      query,
      author: { uuid, locale },
    } = this.props

    return (
      <div className="author-language-switcher">
        <span className="author-language-switcher__language">{locale}</span>
        <Linka
          className="author-language-switcher__label"
          path={urlFor.authorLanguageVariants(uuid, query)}
          pseudo
          rel="nofollow"
          onClick={this.toggleDropdown}
        />
        <Dropdown
          position="top-center"
          offsetTop={DROPDOWN_TOP_OFFSET}
          onClose={this.closeDropdown}
          hidden={!this.state.dropdownShown}
        >
          <div className="author-language-switcher__dropdown-content">
            {this.renderLanguageVariants()}
          </div>
        </Dropdown>
      </div>
    )
  }

  renderLanguageVariants() {
    return this.filteredLanguageVariants.map((languageVariant, index) => (
      <AuthorLanguageVariant
        query={this.props.query}
        key={index}
        languageVariant={languageVariant}
        kind="dropdown-content"
      />
    ))
  }
}

export default uiBox(AuthorLanguageSwitcher)
