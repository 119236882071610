import React, { PureComponent } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import flatMap from 'lodash/flatMap'

import urlHelper, { QueryParams } from 'shared/tools/url-helper'
import { safePluralizeResourceType } from 'client/bookmate/helpers/showcase-helpers'
import Linka from 'client/shared/blocks/linka'

import type { HierarchyProps } from 'client/shared/types/topic'
import { TYPE_LIBRARY } from 'client/shared/types/showcase'

import './breadcrumbs.styl'
import { ResourceName } from 'client/shared/types/resource'

export type BreadcrumbsProps = {
  hierarchy: Pick<HierarchyProps, 'title' | 'link'>[]
  resourceType?:
    | ResourceName
    | typeof TYPE_LIBRARY
    | 'bookshelf'
    | 'post'
    | 'series'
    | 'banner'
  withLibrary?: boolean
  all: boolean
}

type Props = {
  breadcrumbs: BreadcrumbsProps
  query?: QueryParams
} & withI18nProps

class _Breadcrumbs extends PureComponent<Props> {
  createCrumbsInfo = () => {
    const {
      breadcrumbs: { hierarchy, withLibrary = true, all, resourceType },
      i18n,
      query,
    } = this.props
    const crumbs = [...hierarchy]

    const resource = resourceType
      ? safePluralizeResourceType(resourceType)
      : resourceType

    const title = resource
      ? i18n._(`miscellaneous.${resource}`)
      : i18n._('shared.library')

    if (withLibrary) {
      crumbs.unshift({
        title,
        link: urlHelper.showcaseByResource(resource, query),
      })
    }

    if (all) {
      crumbs.push({
        title: i18n._('shared.all'),
        link: false,
      })
    }

    return crumbs
  }

  createCrumbsElements = () => {
    const crumbs = this.createCrumbsInfo()

    return flatMap(crumbs, (item, index) => {
      const key = `${item.title}_${index}`

      return item.link ? (
        <li
          className="breadcrumbs__item"
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
          key={key}
        >
          <Linka
            itemProp="item"
            key={item.title as string}
            className="breadcrumbs__link"
            path={item.link as string}
          >
            <span itemProp="name">{item.title}</span>
          </Linka>
          <meta itemProp="position" content={String(index + 1)} />
        </li>
      ) : (
        <li className="breadcrumbs__item" key={key}>
          <span key={item.title as string} className="breadcrumbs__current">
            {item.title}
          </span>
        </li>
      )
    })
  }

  render(): JSX.Element | null {
    const {
      breadcrumbs: { hierarchy },
    } = this.props

    if (!hierarchy) return null

    return (
      <ol
        className="breadcrumbs"
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        {this.createCrumbsElements()}
      </ol>
    )
  }
}

export const Breadcrumbs = withI18n({ update: true })(_Breadcrumbs)
