import React, { Component } from 'react'
import { Trans, withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'
import urlHelper from 'shared/tools/url-helper'
import './author-info.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { Breadcrumbs } from 'client/shared/blocks/breadcrumbs'
import Spacer from 'client/shared/blocks/spacer'
import LayoutInfo from 'client/bookmate/blocks/layout-info'

import { UserAvatar } from 'client/shared/blocks/user-avatar'
import { ShareButton } from 'client/shared/blocks/share-button'
import { Heading } from 'client/shared/blocks/heading'
import AuthorLanguageSwitcher from 'client/bookmate/blocks/author-language-switcher'
import HorizontalScroller from 'client/shared/blocks/horizontal-scroller'

import { AuthorProps } from 'client/shared/types/author'
import { AuthorLanguageVariant } from 'client/bookmate/reducers/author-reducer'
import {
  aboutTabHelper,
  generateTabs,
  PreparedTab,
} from 'client/shared/helpers/tabs-helper'
import ResourceTabs from '../resource-tabs/resource-tabs'
import { ResourceName } from 'client/shared/types/resource'
import { QuoteProps } from 'client/shared/types/quote'
import { ImpressionProps } from 'client/shared/types/impression'
import { QueryParams } from 'shared/tools/url-helper'
import { isShareAvailable } from 'client/shared/helpers/socials-helpers'
import FollowButtonBox from 'client/bookmate/boxes/follow-button-box'
import { connect } from 'react-redux'
import { State } from 'shared/types/redux'
import UsersList from '../users-list/users-list'
import { UserShort } from 'client/shared/types/user'

type Props = {
  author: AuthorProps
  languageVariants: AuthorLanguageVariant[]
  query: QueryParams
  pathname: string
  hasSeries: boolean
  hasComicbooks: boolean
  hasBooks: boolean
  hasAudiobooks: boolean
  booksAuthor: Role
  audiobooksAuthor: Role
  comicbooksAuthor: Role
  quotes: QuoteProps[]
  country: string
  locale: string
  impressions: ImpressionProps[]
  following: boolean
} & DecoratorProps &
  withI18nProps

type Role = {
  role: string
  worksType: ResourceName
}
class _AuthorInfo extends Component<Props> {
  getShareData() {
    const {
      query,
      app: { domain },
      locale,
      author: { name, uuid } = {},
    } = this.props
    const url = urlHelper.authorWithDomain({
      authorId: uuid as string,
      query,
      locale,
      domain,
    })

    return {
      url,
      twitter: {
        title: name,
        url,
      },
      facebook: {
        url,
      },
    }
  }

  render(): JSX.Element {
    return (
      <div className="author-info">
        <LayoutInfo
          lead={() =>
            this.props.isMobileSize()
              ? this.getMobileLead()
              : this.getDesktopLead()
          }
          header={() =>
            this.props.isMobileSize()
              ? this.getMobileHeader()
              : this.getDesktopHeader()
          }
          hideSpacer
          forceColumns={this.props.isTabletSize()}
        />
      </div>
    )
  }

  getDesktopLead = (): JSX.Element | null => {
    const { author, languageVariants, query } = this.props

    return author ? (
      <div className="author-info__lead">
        <UserAvatar
          user={author}
          size={{
            desktopLarge: 176,
            desktopMedium: 176,
            desktop: 124,
            tablet: 124,
            mobile: 88,
          }}
        />
        <AuthorLanguageSwitcher
          query={query}
          author={author}
          languageVariants={languageVariants}
        />
      </div>
    ) : null
  }

  getMobileLead = (): JSX.Element | null => {
    const { author } = this.props

    return author ? (
      <>
        <div className="author-info lead">{this.getBreadCrumbs()}</div>
        <Spacer />
      </>
    ) : null
  }

  getFollowersElement = () => {
    const {
      author: { friend_followers, followers_count },

      query,
    } = this.props
    return friend_followers?.length ? (
      <UsersList
        query={query}
        isUserInfoPage
        allFriends
        users={friend_followers as UserShort[]}
        usersCount={followers_count as number}
        message="profile.common_followers"
      />
    ) : null
  }
  getBreadCrumbs = () => {
    const {
      author: { name },
      query,
    } = this.props

    const breadcrumbs = {
      hierarchy: [
        { title: <Trans id="search.authors_header" />, link: false },
        { title: name, link: false },
      ],
      all: false,
    }
    return <Breadcrumbs breadcrumbs={breadcrumbs} query={query} />
  }

  getMobileHeader = (): JSX.Element => {
    const {
      author: { name, uuid },
      author,
      pathname,
      query,
      languageVariants,
      country,
      following,
    } = this.props

    const tabs = this.getTabs()
    const isAboutTab = aboutTabHelper(pathname, uuid)

    return (
      <div className="author-info">
        <div className="header-mobile">
          <div className="header_col">
            <UserAvatar
              user={author}
              size={{
                desktopLarge: 176,
                desktopMedium: 176,
                desktop: 124,
                tablet: 124,
                mobile: 88,
              }}
            />
          </div>
          <div className="header_col">
            <Spacer />
            <Heading kind="kazimir" looksLike={3}>
              {name}
            </Heading>
            <AuthorLanguageSwitcher
              query={query}
              author={author}
              languageVariants={languageVariants}
            />
            <Spacer />
            {this.getFollowersElement()}
            <Spacer />
          </div>
        </div>
        <div className="author_buttons">
          <FollowButtonBox
            author={author}
            following={following}
            width="160px"
          />
          {isShareAvailable(country) && (
            <ShareButton
              kind="medium"
              shareData={this.getShareData()}
              analytics={{
                object_type: 'author',
                object_id: name,
              }}
            />
          )}
        </div>
        <Spacer size={24} />
        {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore **/}
        <HorizontalScroller>
          <ResourceTabs tabs={tabs} isAboutTab={isAboutTab} />
        </HorizontalScroller>
      </div>
    )
  }
  getDesktopHeader = (): JSX.Element => {
    const {
      author: { name, uuid },
      author,
      pathname,
      country,
      following,
    } = this.props

    const tabs = this.getTabs()
    const isAboutTab = aboutTabHelper(pathname, uuid)

    return (
      <div className="author-info__header">
        {this.getBreadCrumbs()}
        <Spacer />
        <Heading kind="kazimir" looksLike={1}>
          {name}
        </Heading>
        <Spacer />
        <Spacer />
        <div className="author_buttons">
          <FollowButtonBox
            author={author}
            following={following}
            width="160px"
          />
          {isShareAvailable(country) && (
            <ShareButton
              kind="medium"
              shareData={this.getShareData()}
              analytics={{
                object_type: 'author',
                object_id: name,
              }}
            />
          )}
        </div>
        <Spacer size={24} />
        {this.getFollowersElement()}
        <Spacer size={48} />
        {/** eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore **/}
        <HorizontalScroller>
          <ResourceTabs tabs={tabs} isAboutTab={isAboutTab} />
        </HorizontalScroller>
      </div>
    )
  }

  getTabs(): PreparedTab[] {
    const {
      i18n,
      query,
      hasSeries,
      hasBooks,
      hasAudiobooks,
      hasComicbooks,
      booksAuthor,
      comicbooksAuthor,
      audiobooksAuthor,
      quotes,
      impressions,
      author: { external_links, uuid },
    } = this.props

    const AVAILABLE_TABS = [
      { tabTitle: i18n._('miscellaneous.overview'), count: true },
      {
        tabType: `${booksAuthor.role}/books`,
        tabTitle: i18n._('miscellaneous.books'),
        count: Boolean(hasBooks && booksAuthor),
      },
      {
        tabType: `${audiobooksAuthor.role}/audiobooks`,
        tabTitle: i18n._('miscellaneous.audiobooks'),
        count: Boolean(hasAudiobooks && audiobooksAuthor),
      },
      {
        tabType: `${comicbooksAuthor.role}/comicbooks`,
        tabTitle: i18n._('miscellaneous.comicbooks'),
        count: Boolean(hasComicbooks && comicbooksAuthor),
      },
      {
        tabType: 'author/series',
        tabTitle: i18n._('search.series_header'),
        count: hasSeries,
      },
      {
        tabType: 'author/quotes',
        tabTitle: i18n._('book.quotes_header'),
        count: quotes.length > 0,
      },
      {
        tabType: 'author/impressions',
        tabTitle: i18n._('book.impressions_header'),
        count: impressions.length > 0,
      },
      {
        tabType: 'author/media-links',
        tabTitle: i18n._('miscellaneous.more_about_author'),
        count: external_links.length > 0,
      },
    ]

    const tabs = generateTabs({
      resourceType: 'author',
      uuid,
      tabs: AVAILABLE_TABS.filter(tab => tab.count),
      query,
    })

    return tabs
  }
}

type AuthorLifeProps = {
  bornAt: string
  diedAt?: string | null
}

export const AuthorLifeDates: React.FC<AuthorLifeProps> = ({
  bornAt,
  diedAt,
}) => (
  <div className="author-info__life">
    <Trans id="miscellaneous.life" />: {bornAt} –{' '}
    {diedAt ? diedAt : <Trans id="miscellaneous.present_time" />}
  </div>
)

const connectWrapper = connect((state: State) => ({
  following: state.author.following,
}))

const wrappers = compose(connectWrapper, withI18n({ update: true }), uiBox)

export const AuthorInfo = wrappers(_AuthorInfo)
