import React, { Component } from 'react'

import './layout-info.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { LayoutColumn } from 'client/shared/blocks/layout-column'
import Spacer from 'client/shared/blocks/spacer'

type Props = {
  lead: () => Node | Node
  header: () => Node
  body: () => Node
  aside: () => Node
  withFloatingCols?: boolean
  hideSpacer?: boolean
  forceColumns?: boolean
  size?: 10 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 12 | 13 | 14 | undefined
} & DecoratorProps

const renderNull = () => null

class LayoutInfo extends Component<Props> {
  static defaultProps = {
    lead: renderNull,
    header: renderNull,
    body: renderNull,
    aside: renderNull,
  }

  render(): JSX.Element {
    const { isOneColumnSize, withFloatingCols, forceColumns } = this.props

    let { hideSpacer } = this.props

    if (withFloatingCols) hideSpacer = withFloatingCols

    return (
      <div className="layout-info">
        <Spacer size="top-page-compact" />
        {isOneColumnSize() && !forceColumns
          ? this.renderOneColumn()
          : this.checkRenderFullOptions()}
        {!hideSpacer && <Spacer size={32} />}
      </div>
    )
  }

  checkRenderFullOptions(): JSX.Element {
    const { withFloatingCols } = this.props

    return withFloatingCols
      ? this.renderFullResourceLayout()
      : this.renderFull()
  }

  renderFull() {
    const { lead, header, body, aside, isDesktopSize } = this.props

    return (
      <div className="layout-info__wrapper">
        <LayoutColumn size={isDesktopSize() ? 3 : 4}>
          <div className="layout-info__lead">
            {typeof lead === 'function' ? lead() : lead}
          </div>
        </LayoutColumn>
        <LayoutColumn size={8}>
          {header()}
          {body()}
        </LayoutColumn>
        <LayoutColumn size={isDesktopSize() ? 3 : 4}>{aside()}</LayoutColumn>
      </div>
    )
  }

  renderFullResourceLayout(): JSX.Element {
    const { lead, header, body, aside, isDesktopSize } = this.props

    return (
      <div className="layout-info__wrapper">
        <div className="book-layout-info__column-balancer">
          <div className="book-layout-info__col-left">
            <LayoutColumn size={isDesktopSize() ? 3 : 4}>
              <div className="layout-info__lead">
                {typeof lead === 'function' ? lead() : lead}
              </div>
            </LayoutColumn>
          </div>
          <div className="book-layout-info__col-right">
            <LayoutColumn size={isDesktopSize() ? 3 : 4}>
              {aside()}
            </LayoutColumn>
          </div>
        </div>
        <div className="book-layout-info__col-main">
          <LayoutColumn size={8}>
            {header()}
            {body()}
          </LayoutColumn>
        </div>
      </div>
    )
  }

  renderOneColumn(): JSX.Element {
    const { lead, header, body } = this.props

    return (
      <div>
        <LayoutColumn>
          <div>
            <div className="layout-info__top">
              <div className="layout-info__lead">
                {typeof lead === 'function' ? lead() : lead}
              </div>
              <div className="layout-info__header">{header()}</div>
            </div>
            {body()}
          </div>
        </LayoutColumn>
      </div>
    )
  }
}

export default uiBox(LayoutInfo)
