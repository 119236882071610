import React from 'react'

import './author-featured-work.styl'

import { BookCover } from 'client/shared/blocks/book-cover'
import AudioBookCover from 'client/shared/blocks/audiobook-cover'
import Linka from 'client/shared/blocks/linka'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import TextTruncate from 'client/shared/blocks/text-truncate'
import PlaceholderBackground from 'client/shared/blocks/placeholder-background'

import { resourceTypes } from 'client/shared/helpers/resource-helpers'
import { AuthorWork } from 'client/shared/types/author'
const { AUDIOBOOK } = resourceTypes

export default function renderFeatured({
  work,
  query,
}: {
  work: AuthorWork
  query?: QueryParams
}): JSX.Element {
  const { annotation, topic, resource_type, resource } = work

  const isAudiobookCover = resource_type === AUDIOBOOK
  const path = isAudiobookCover
    ? urlFor.audioBook(resource.uuid, query)
    : urlFor[resource_type](resource.uuid, query)

  return (
    <div className="author-featured">
      <Linka
        path={path}
        className="author-featured__fake-link"
        aria-label="post"
        id={`featured-post-link_${resource.uuid}`}
      />
      <div className="author-featured__content-container">
        <div className="author-featured__book-cover-container">
          {isAudiobookCover ? (
            <AudioBookCover
              audiobook={resource}
              size={{
                desktopLarge: 120,
                desktopMedium: 120,
                desktop: 120,
                tablet: 120,
                mobile: 88,
              }}
            />
          ) : (
            <BookCover
              book={resource}
              size={{
                desktopLarge: 120,
                desktopMedium: 120,
                desktop: 120,
                tablet: 120,
                mobile: 88,
              }}
            />
          )}
        </div>
        <div className="author-featured__text-container">
          <div className="author-featured__book-title">
            <TextTruncate lines={2} text={resource.title} textType="title" />
            <div className="author-featured__topic">{topic.title}</div>
          </div>
          <div className="author-featured__annotation">{annotation}</div>
        </div>
      </div>
      <div className="author-featured__image-background">
        <PlaceholderBackground placeholder={resource.cover.placeholder || ''} />
      </div>
      <div className="author-featured__darkened-background" />
    </div>
  )
}
