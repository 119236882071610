import React, { Component } from 'react'
import noop from 'lodash/noop'

import './emotions.styl'

import Emotion from 'client/bookmate/blocks/emotion'

import { EmotionProps } from 'client/shared/types/emotion'

type Props = {
  items: EmotionProps[]
  active: EmotionProps[]
  selectable: boolean
  onClick: (
    e: React.SyntheticEvent<HTMLDivElement>,
    arg1: string,
    arg2: boolean,
  ) => void
}

class Emotions extends Component<Props> {
  static defaultProps = {
    active: [],
    selectable: false,
    onClick: noop,
  }

  render(): JSX.Element {
    const { items, selectable, active, onClick, ...restProps } = this.props

    return (
      <div className="emotions" {...restProps}>
        {items.map((emotion, i) => {
          return (
            <Emotion
              key={i}
              {...emotion}
              selected={active.some(
                activeEmotion => activeEmotion.name === emotion.name,
              )}
              selectable={selectable}
              onClick={onClick}
            />
          )
        })}
      </div>
    )
  }
}

export default Emotions
