import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'

import Linka from 'client/shared/blocks/linka'
import ShareLinkIcon from 'client/shared/icons/share_link.svg'
import TextTruncate from 'client/shared/blocks/text-truncate'

import './external-link.styl'

export type ExternalLinkType = {
  title: string
  description: string
  url: string
  image_url: string
}

type Props = {
  externalLink: ExternalLinkType
  onClick?: () => void
  id?: string
  kind: string
}
class ExternalLink extends Component<Props> {
  static defaultProps = {
    kind: 'list',
  }

  render(): JSX.Element {
    const {
      externalLink: { title, description, url, image_url },
      kind,
      onClick,
      id,
    } = this.props

    const urlText = url.replace(/^https?:\/\//, '')?.split('/')[0]

    return (
      <div
        className={`external-link external-link_${kind}`}
        id={id}
        onClick={onClick}
      >
        <Linka path={url} className="external-link__link" target="_blank">
          <div className="external-link__info">
            <div className="external-link__title">
              <TextTruncate lines={2} text={title} textType="title" />
            </div>
            <div className="external-link__description">
              <TextTruncate lines={3} text={description} />
            </div>
            <div className="external-link__source-wrapper">
              <div className="external-link__image-wrapper">
                <img className="external-link__image" src={image_url} />
              </div>
              <div className="external-link__source">{urlText}</div>
              <SVGInline
                svg={ShareLinkIcon}
                className="external-link__share-icon"
              />
            </div>
          </div>
        </Linka>
      </div>
    )
  }
}

export default ExternalLink
