import React, { Component } from 'react'
import cn from 'classnames'

import { Tab } from 'client/shared/blocks/tab'
import { getTabType } from 'client/shared/helpers/tabs-helper'

import { BookTabProps } from 'client/shared/types/book'

import './resource-tabs.styl'

type Props = {
  tabs: BookTabProps[]
  isAboutTab: boolean
}

class ResourceTabs extends Component<Props> {
  forceAboutTabState(
    isAboutTab: boolean,
    aboutTab: { title: string; link: string },
  ): JSX.Element {
    return isAboutTab ? (
      <li className="resource-tabs__item" key={aboutTab.title}>
        <Tab path={aboutTab.link} active disableLoginWall>
          {aboutTab.title}
        </Tab>
      </li>
    ) : (
      <li
        className="resource-tabs__item resource-tabs__item-inactive"
        key={aboutTab.title}
      >
        <Tab path={aboutTab.link} active={false} disableLoginWall>
          {aboutTab.title}
        </Tab>
      </li>
    )
  }

  render(): JSX.Element {
    const { tabs, isAboutTab } = this.props
    const aboutTab = tabs.shift()

    const MAX_TABS_FIT_PER_COLUMN = 4

    return (
      <ul
        className={cn('resource-tabs', {
          'resource-tabs__has-many-tabs':
            tabs.length >= MAX_TABS_FIT_PER_COLUMN,
        })}
      >
        {this.forceAboutTabState(isAboutTab, aboutTab)}
        {tabs.map(this.renderTab)}
      </ul>
    )
  }

  renderTab(tab: BookTabProps): JSX.Element {
    const tabType = getTabType(tab.link)
    const shouldDisableLoginWall = !/(readers|listeners|shelves)/gm.exec(
      tabType,
    )

    return (
      <li className="resource-tabs__item" key={tab.title}>
        <Tab
          path={tab.link}
          active={tab.active}
          disableLoginWall={shouldDisableLoginWall}
          pseudo={tab.pseudo}
        >
          {tab.title}
          {tab.hasItemsCount && (
            <span className="tab__count">{tab.hasItemsCount}</span>
          )}
        </Tab>
      </li>
    )
  }
}

export default ResourceTabs
