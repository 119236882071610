import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push, replace } from 'react-router-redux'
import { withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'

import errorBoundary from 'client/shared/decorators/error-boundary'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import { trimText } from 'client/shared/helpers/text-helpers'
import { focusCommentForm } from 'client/shared/helpers/dom-helpers'

import { Book } from 'client/shared/blocks/book'
import AudioBook from 'client/bookmate/blocks/audiobook'
import { Comicbook } from 'client/shared/blocks/comicbook'
import SimpleFormat from 'client/shared/blocks/simple-format'
import { Card } from 'client/bookmate/blocks/card'
import Emotions from 'client/bookmate/blocks/emotions'

import {
  throttledToggleLike as likeImpression,
  remove as removeImpression,
} from 'client/bookmate/reducers/impressions-reducer'

import { report as reportProblem } from 'client/bookmate/reducers/problem-form-reducer'
import { show as showAuthPopup } from 'client/shared/reducers/popup-reducer'

import DeleteIcon from 'client/shared/icons/delete.svg'
import EditIcon from 'client/shared/icons/edit.svg'
import ReportIcon from 'client/shared/icons/exclam.svg'

import { ResourceName, ResourceProps } from 'client/shared/types/resource'
import { ImpressionProps } from 'client/shared/types/impression'
import { CurrentUserState } from 'client/shared/types/current-user'
import { Dispatch, State } from 'shared/types/redux'
import { MenuDataItem } from 'client/shared/blocks/context-menu'
import type { Exact } from 'types/local/exact-check'
import { changeImpressionLikesCount } from '../reducers/author-reducer'

const IMPRESSION_MAX_LENGTH = 90

type Props = Exact<withI18nProps> & {
  impression: ImpressionProps
  embedded?: ResourceProps
  kind: 'default' | 'page'
  extraHeader?: React.ReactNode
  menuItems?: MenuDataItem[]
  currentUser: CurrentUserState
  dispatch: Dispatch
  domain?: string
  entryType?: string
  isAuthorPage?: boolean
  query?: QueryParams
  as?: string
}

class _CardImpressionBox extends Component<Props> {
  static defaultProps = {
    kind: 'default',
  }

  getTextSize(): string {
    const { kind, impression } = this.props

    if (kind === 'page' && impression.content.length < 200) {
      return 'font-size-xx-large'
    } else {
      return 'default-size'
    }
  }

  getEditLink(uuid: string, resourceType: ResourceName): string {
    const { query } = this.props
    switch (resourceType) {
      case 'book':
        return urlFor.bookImpressionForm(uuid, query)

      case 'audiobook':
        return urlFor.audioBookImpressionForm(uuid, query)

      case 'comicbook':
        return urlFor.comicbookImpressionForm(uuid, query)

      case 'serial':
        return urlFor.serialImpressionForm(uuid, query)
    }
  }

  toggleLike = (): void => {
    const {
      currentUser: { auth },
      impression: { uuid, liked, likes_count },
      dispatch,
      isAuthorPage,
    } = this.props

    if (!auth) {
      return dispatch(
        showAuthPopup({
          callback: () =>
            likeImpression({ uuid, liked, likes_count, dispatch }),
        }),
      )
    }

    likeImpression({ uuid, liked, likes_count, dispatch })
    if (isAuthorPage) {
      dispatch(changeImpressionLikesCount({ uuid, likes_count, liked }))
    }
  }

  isMine() {
    const {
      currentUser: { data: currentUser },
      impression,
    } = this.props

    return impression.creator && impression.creator.login === currentUser.login
  }

  getMenuData() {
    const { i18n, menuItems: menuItemsFromParent } = this.props
    const position = 'top-right'

    if (menuItemsFromParent) {
      return {
        position,
        items: menuItemsFromParent,
      }
    } else {
      let items = [
        {
          icon: ReportIcon,
          title: i18n.t`context-menu.complain`,
          action: this.onComplainClick,
        },
      ]

      if (this.isMine()) {
        items = [
          {
            icon: EditIcon,
            title: i18n.t`context-menu.edit`,
            action: this.onEditClick,
          },
          {
            icon: DeleteIcon,
            title: i18n.t`shared.remove`,
            action: this.onRemoveClick,
          },
        ]
      }
      return {
        position,
        items,
      }
    }
  }

  get shareData() {
    const {
      impression,
      currentUser: {
        data: { locale },
      },
      domain,
    } = this.props
    const url = `${urlFor.absoluteRootWithSubdomain(
      locale,
      domain,
    )}${urlFor.impression({ impressionUuid: impression.uuid })}`

    return {
      facebook: { url },
      twitter: {
        title: this.generateTwitterShare(impression),
        url,
      },
    }
  }

  generateTwitterShare(impression: ImpressionProps): string {
    const text =
      impression.emotions &&
      impression.emotions.reduce((acc, emotion) => {
        return `${acc} ${emotion.unicode} ${emotion.label}`
      }, '')
    return `${text}\n${trimText(impression.content, IMPRESSION_MAX_LENGTH)}\n`
  }

  onEditClick = (): void => {
    const {
      impression: {
        resource: { uuid, resourceType },
      },
      dispatch,
    } = this.props

    dispatch(push(this.getEditLink(uuid, resourceType)))
  }

  onRemoveClick = (): void => {
    const {
      impression: { uuid, resource, resourceType },
      kind,
      i18n,
      dispatch,
    } = this.props

    if (confirm(i18n.t`context-menu.impression_remove_confirm`)) {
      const removeImpressionArgs = [uuid, { resource, resourceType }]
      if (kind === 'page') {
        removeImpressionArgs.push(this.redirectToItemPage)
      }
      dispatch(removeImpression.apply(this, removeImpressionArgs))
    }
  }

  onComplainClick = (): void => {
    const {
      impression: { uuid },
      dispatch,
    } = this.props
    dispatch(
      reportProblem({
        content_problem: {
          problem_kind: 'complaint',
          problem_type: 'text',
          resource_type: 'document_impression',
          resource_uuid: uuid,
        },
      }),
    )
  }

  redirectToItemPage = (): void => {
    const {
      impression: {
        resource: { uuid, resourceType },
      },
      dispatch,
      query,
    } = this.props

    dispatch(replace(urlFor.resource(uuid, resourceType, query) as string))
  }

  openComments = () => {
    const {
      currentUser: { auth },
      dispatch,
    } = this.props

    if (!auth) {
      return dispatch(
        showAuthPopup({
          callback: this.onCommentsClick,
        }),
      )
    }

    this.onCommentsClick()
  }

  onCommentsClick = (): void => {
    const { impression, dispatch, query } = this.props

    dispatch(
      push(urlFor.impression({ impressionUuid: impression.uuid, query })),
    )

    focusCommentForm()
  }

  render(): JSX.Element {
    const {
      impression,
      kind,
      extraHeader,
      currentUser: { auth: isLoggedIn },
      dispatch,
      query,
      as,
    } = this.props

    const path = urlFor.impression({ impressionUuid: impression.uuid, query })

    const header = {
      user: impression.creator,
      actionMessage: 'card.shared_impression',
      date: impression.created_at * 1000,
      path,
      isLoggedIn,
      showAuthPopup: () => dispatch(showAuthPopup()),
    }

    const footer = {
      likeCount: impression.likes_count,
      commentCount: impression.comments_count,
      liked: impression.liked,
      addToShelfLink: urlFor.impressionAddToShelf(impression.uuid, query),
      shareData: this.shareData,
      menuData: this.getMenuData(),
      analytics: {
        object_type: 'impression',
        object_id: impression.uuid,
      },
    }

    return (
      <Card
        kind={kind}
        header={header}
        footer={footer}
        extraHeader={extraHeader}
        onLikeClick={this.toggleLike}
        onCommentClick={this.openComments}
        as={as}
      >
        {impression.emotions && <Emotions items={impression.emotions} />}
        <SimpleFormat
          text={impression.content}
          element="p"
          classModifiers={[this.getTextSize(), 'default-font']}
        />
        {this.renderEmbeddedResource()}
      </Card>
    )
  }

  renderEmbeddedResource(): JSX.Element | null | undefined {
    const { embedded } = this.props

    if (!embedded) return null

    const { resourceType } = embedded

    if (resourceType === 'book') {
      return <Book book={embedded} kind="list" />
    } else if (resourceType === 'audiobook') {
      return <AudioBook audiobook={embedded} kind="list" />
    } else if (resourceType === 'comicbook') {
      return <Comicbook comicbook={embedded} kind="list" />
    } else if (resourceType === 'serial') {
      return <Book book={embedded} kind="list" />
    }
  }
}

const wrappers = compose(
  connect((state: State) => ({
    currentUser: state.currentUser,
    domain: state.app.domain,
    query: state.app.storedQuery,
  })),
  errorBoundary,
  withI18n({ update: true }),
)

export const CardImpressionBox = wrappers(_CardImpressionBox)
