import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty'
import { Trans } from '@lingui/react'

import urlFor from 'shared/tools/url-helper'
import {
  IMPRESSIONS_LIMIT,
  loadAuthorTopics,
  loadMoreWorksForSingleRoleAndWorksType,
  QUOTES_LIMIT,
} from 'client/bookmate/reducers/author-reducer'
import {
  mapStateToPropsForAuthor,
  renderAuthorWorks,
  renderMediaLinks,
  MappedAuthorProps,
} from 'client/bookmate/helpers/author-helpers'

import withMarketing, {
  MarketingProps,
} from 'client/shared/decorators/with-marketing'
import errorBoundary, {
  ErrorBoundaryProps,
} from 'client/shared/decorators/error-boundary'

import AuthorFeaturedWork from 'client/bookmate/blocks/author-featured-work'
import { Heading } from 'client/shared/blocks/heading'
import { LayoutColumn } from 'client/shared/blocks/layout-column'
import Spacer from 'client/shared/blocks/spacer'
import ExpandableText from 'client/shared/blocks/expandable-text'

import type { Dispatch } from 'shared/types/redux'
import type { Exact } from 'types/local/exact-check'
import { Location } from 'client/shared/types/react-router'
import MoreButton from 'client/shared/blocks/more-button/more-button'
import { AuthorRole, AuthorWorkTypes } from 'client/shared/types/author'
import { AuthorLifeDates } from '../blocks/author-info/author-info'
import { CardQuoteBox } from './card-quote-box'
import TopicList from '../blocks/topic-list/topic-list'
import Topic from '../blocks/topic/topic'
import { ResourceTopicProps } from 'client/shared/types/topic'
import { CardImpressionBox } from './card-impression-box'

type Props = (Exact<MappedAuthorProps> &
  Exact<MarketingProps> & {
    dispatch: Dispatch
  }) &
  ErrorBoundaryProps & {
    location: Location
  }

type State = {
  moreTopicsClosed: boolean
}

const EXTENDED_NUMBER_OF_WORKS_PER_GROUP = 20

class AuthorIndexBox extends Component<Props, State> {
  state = {
    moreTopicsClosed: true,
  }
  componentDidMount(): void {
    if (!isEmpty(this.props.author))
      this.checkWhetherShouldLoadMoreWorks(this.props as Props)
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      !isEmpty(this.props.author) &&
      ((!prevProps.author && this.props.author) ||
        prevProps.author.uuid !== this.props.author.uuid ||
        prevProps.authorMeta.sectionsOrder.length !==
          this.props.authorMeta.sectionsOrder.length)
    ) {
      this.checkWhetherShouldLoadMoreWorks(this.props as Props)
    }
  }

  hasSingleRoleAndWorksType(): boolean {
    return this.props.authorMeta.sectionsOrder.length === 1
  }

  canShowMoreWorksOfOnlyType(): boolean {
    const { role, worksType } = this.props.authorMeta.sectionsOrder[0]
    return (
      this.props[worksType][role].length < EXTENDED_NUMBER_OF_WORKS_PER_GROUP
    )
  }

  shouldLoadMoreWorks(): boolean {
    return this.hasSingleRoleAndWorksType() && this.canShowMoreWorksOfOnlyType()
  }

  checkWhetherShouldLoadMoreWorks(props: Props): void {
    const {
      authorMeta: { uuid: authorId, sectionsOrder },
    } = props

    // if there is only one role and one type of author's works,
    // load more resources immediately after rendering
    if (this.shouldLoadMoreWorks()) {
      const { role, worksType } = sectionsOrder[0]
      this.loadMoreWorksForSingleRoleAndWorksType({
        authorId,
        role,
        worksType,
      })
    }
  }

  loadMoreWorksForSingleRoleAndWorksType = ({
    authorId,
    role,
    worksType,
  }: {
    authorId: string
    role: string
    worksType: string
  }): void => {
    const { dispatch } = this.props

    dispatch(
      loadMoreWorksForSingleRoleAndWorksType({ authorId, role, worksType }),
    )
  }

  loadAllTopics(uuid: string): void {
    this.setState({ moreTopicsClosed: false })
    this.props.dispatch(
      loadAuthorTopics(uuid, this.props.authorMeta.topicsTotal),
    )
  }

  render(): JSX.Element | null {
    if (isEmpty(this.props.author)) return null
    const {
      userId,
      dispatch,
      authorMeta: {
        sectionsOrder,
        external_links: { links },
        impressions: { content: impressions },
        uuid,
        roles,
        quotes: { content: quotes },
        topics,
        topicsTotal,
      },
      author: { featured_work, about, born_at, died_at, locale },
      app,
    } = this.props

    const LIMIT = 3

    const sections = sectionsOrder.map(({ role, worksType }, index) => (
      <div key={index}>
        {renderAuthorWorks(this.props, {
          role,
          sectionIndex: index + 1,
          worksType,
          itemsType: LIST,
          limit: LIMIT,
          withSectionHeader: true,
          showAllButton: true,
          headerRank: 2,
        })}
        {roles[role][worksType].total_count > LIMIT && (
          <>
            <Spacer size={24} />
            <MoreButton
              path={urlFor.authorWorks({
                authorId: uuid,
                role: role as AuthorRole,
                worksType: worksType as AuthorWorkTypes,
                query: app.storedQuery,
              })}
              centered
            >
              <Trans id="topic.show_all" />
            </MoreButton>
          </>
        )}
        <Spacer size={48} />
      </div>
    ))

    if (quotes.length > 0) {
      sections.push(
        <>
          <Spacer size={48} />
          <Heading rank={2}>
            <Trans id="book.quotes_header" />
          </Heading>
          <Spacer />
          {quotes.slice(0, QUOTES_LIMIT).map((quote, i) => (
            <CardQuoteBox quote={quote} key={i} isAuthorPage />
          ))}
          {quotes.length > QUOTES_LIMIT && (
            <>
              <Spacer size={24} />
              <MoreButton
                path={urlFor.authorQuotes(uuid, app.storedQuery)}
                centered
              >
                <Trans id="topic.show_all" />
              </MoreButton>
            </>
          )}
          <Spacer size={48} />
        </>,
      )
    }

    if (impressions.length > 0) {
      sections.push(
        <>
          <Spacer size={48} />
          <Heading rank={2}>
            <Trans id="book.impressions_header" />
          </Heading>
          <Spacer />
          {impressions.slice(0, IMPRESSIONS_LIMIT).map((impression, i) => (
            <CardImpressionBox
              impression={impression}
              embedded={{
                ...impression.resource,
                resourceType: impression.resource.type?.toLowerCase(),
              }}
              key={i}
              isAuthorPage
            />
          ))}
          {impressions.length > IMPRESSIONS_LIMIT && (
            <>
              <Spacer size={24} />
              <MoreButton
                path={urlFor.authorImpressions(uuid, app.storedQuery)}
                centered
              >
                <Trans id="topic.show_all" />
              </MoreButton>
            </>
          )}
          <Spacer size={48} />
        </>,
      )
    }

    if (links.length > 0) {
      sections.push(
        <>
          {impressions.length > IMPRESSIONS_LIMIT && (
            <>
              {renderMediaLinks({
                dispatch,
                userId,
                external_links: links,
                linkKind: LIST,
                authorUuid: uuid,
                sectionIndex: sections.length + 1,
                query: app.storedQuery,
              })}
              <Spacer size={24} />
              <MoreButton
                path={urlFor.authorMediaLinks(uuid, app.storedQuery)}
                centered
              >
                <Trans id="topic.show_all" />
              </MoreButton>
            </>
          )}
        </>,
      )
    }

    return (
      <div>
        <LayoutColumn size={8} centered>
          {about && (
            <ExpandableText bookLang={locale} text={about} maxHeight={72} />
          )}
          {born_at && (
            <>
              <Spacer size={32} />
              <AuthorLifeDates bornAt={born_at} diedAt={died_at} />
              <Spacer size={32} />
            </>
          )}
          {topics && topics.length > 0 && (
            <>
              <Spacer size={24} />
              <TopicList>
                {topics.map((topic: ResourceTopicProps) => (
                  <Topic {...topic} resource="books" key={topic.uuid} />
                ))}
                {this.state.moreTopicsClosed && topics.length < topicsTotal && (
                  <div
                    className="topic topic__more-button"
                    onClick={() => this.loadAllTopics(uuid)}
                  >
                    <Trans id="auth.more" /> {topicsTotal}
                  </div>
                )}
              </TopicList>
              <Spacer size={24} />
            </>
          )}
          {featured_work && (
            <>
              <Heading rank={2}>
                <Trans id="library.editorial" />
              </Heading>
              <Spacer size={16} />
              <AuthorFeaturedWork
                work={featured_work}
                query={app.storedQuery}
              />
            </>
          )}
          <Spacer size={48} />
          {sections}
          <Spacer size={48} />
        </LayoutColumn>
      </div>
    )
  }
}

export const LIST = 'list'
export const AUTHOR = 'author'

const connectWrapper = connect(mapStateToPropsForAuthor)

const wrappers = compose(connectWrapper, withMarketing, errorBoundary)

export default wrappers(AuthorIndexBox)
