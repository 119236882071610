import React from 'react'
import compose from 'lodash/fp/compose'
import map from 'lodash/fp/map'
import flattenDeep from 'lodash/fp/flattenDeep'
import values from 'lodash/fp/values'
import compact from 'lodash/fp/compact'
import pick from 'lodash/fp/pick'
import uniq from 'lodash/fp/uniq'
import property from 'lodash/fp/property'

import { getBooksByIds } from 'client/bookmate/selectors/book-selector'
import { getAudioBooksByIds } from 'client/bookmate/reducers/audiobooks-reducer'
import { getComicbooksByIds } from 'client/bookmate/reducers/comicbooks-reducer'
import { getSeriesByIds } from 'client/bookmate/selectors/series-selectors'
import { getAuthorById } from 'client/bookmate/reducers/authors-reducer'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import List from 'client/bookmate/blocks/list'
import { Book } from 'client/shared/blocks/book'
import AudioBook from 'client/bookmate/blocks/audiobook'
import { Comicbook } from 'client/shared/blocks/comicbook'
import Series from 'client/shared/blocks/series'
import Linka from 'client/shared/blocks/linka'
import ExternalLink from 'client/bookmate/blocks/external-link'

import { State as AuthorMetaState } from 'client/bookmate/reducers/author-reducer'
import {
  AuthorProps,
  AuthorRole,
  AuthorWorkTypes,
  MinimalAuthorInfo,
  ExternalLink as ExternalLinkType,
} from 'client/shared/types/author'
import { BookProps } from 'client/shared/types/book'
import { AudiobookProps } from 'client/shared/types/audiobook'
import { ComicbookProps } from 'client/shared/types/comicbook'
import { ShelfProps } from 'client/shared/types/shelf'

import { State as AppState } from 'client/shared/reducers/app-reducer'
import { Dispatch, State as ConnectedState } from 'shared/types/redux'
import { sendOnClickAnalytics } from 'shared/tools/analytics-helper'
import { AUTHOR } from '../boxes/author-index-box'
import { BOOK, EXTERNAL_LINK, SERIES } from '../reducers/feed-entries-reducer'
import {
  AUDIOBOOK,
  COMICBOOK,
} from '../blocks/search-best-match/search-best-match'

const HEIGHTS = {
  '78': 56,
  '124': 88,
  '176': 120,
  '247': 176,
  '290': 208,
}

export type MappedAuthorProps = {
  app: AppState
  dispatch: Dispatch
  userId: number
  author: AuthorProps
  authorMeta: AuthorMetaState
  books: {
    author: BookProps[]
    translator: BookProps[]
    publisher: BookProps[]
  }
  audiobooks: {
    author: AudiobookProps[]
    translator: AudiobookProps[]
    narrator: AudiobookProps[]
    publisher: AudiobookProps[]
  }
  comicbooks: {
    author: ComicbookProps[]
    translator: ComicbookProps[]
    illustrator: ComicbookProps[]
    publisher: AudiobookProps[]
  }
  series: {
    author: ShelfProps[]
    publisher: ShelfProps[]
  }
  pages: {
    books: {
      author: number
      translator: number
      publisher: number
    }
    audiobooks: {
      author: number
      narrator: number
      publisher: number
    }
    comicbooks: {
      author: number
      illustrator: number
      publisher: number
    }
    series: {
      author: number
      publisher: number
    }
  }
}

const ListTitleIds = {
  author: {
    books: 'authored_books',
    audiobooks: 'authored_audiobooks',
    comicbooks: 'authored_comicbooks',
    series: 'authored_series',
  },
  translator: {
    books: 'translated_books',
    audiobooks: 'translated_audiobooks',
    comicbooks: 'translated_comicbooks',
  },
  narrator: {
    audiobooks: 'narrated_audiobooks',
  },
  illustrator: {
    comicbooks: 'illustrated_comicbooks',
  },
  publisher: {
    books: 'published_books',
    audiobooks: 'published_audiobooks',
    comicbooks: 'published_comicbooks',
    series: 'published_series',
  },
}

export function mapStateToPropsForAuthor(
  state: ConnectedState,
): Record<string, unknown> {
  return {
    app: state.app,
    author: getAuthorById(state, state.author.uuid),
    authorMeta: state.author,
    books: {
      author: getBooksByIds(state, state.author.roles?.author?.books?.ids),
      translator: getBooksByIds(
        state,
        state.author.roles?.translator?.books?.ids,
      ),
      publisher: getBooksByIds(
        state,
        state.author.roles?.publisher?.books?.ids,
      ),
    },
    audiobooks: {
      author: getAudioBooksByIds(
        state,
        state.author.roles.author.audiobooks.ids,
      ),
      translator: getAudioBooksByIds(
        state,
        state.author.roles.translator.audiobooks.ids,
      ),
      narrator: getAudioBooksByIds(
        state,
        state.author.roles.narrator.audiobooks.ids,
      ),
      publisher: getAudioBooksByIds(
        state,
        state.author.roles.publisher.audiobooks.ids,
      ),
    },
    comicbooks: {
      author: getComicbooksByIds(
        state,
        state.author.roles.author.comicbooks.ids,
      ),
      translator: getComicbooksByIds(
        state,
        state.author.roles.translator.comicbooks.ids,
      ),
      illustrator: getComicbooksByIds(
        state,
        state.author.roles.illustrator.comicbooks.ids,
      ),
      publisher: getComicbooksByIds(
        state,
        state.author.roles.publisher.comicbooks.ids,
      ),
    },
    series: {
      author: getSeriesByIds(state, state.author.roles.author.series.ids),
      publisher: getSeriesByIds(state, state.author.roles.publisher.series.ids),
    },
    pages: {
      books: {
        author: state.author.roles.author.books.page,
        translator: state.author.roles.translator.books.page,
        publisher: state.author.roles.publisher.books.page,
      },
      audiobooks: {
        author: state.author.roles.author.audiobooks.page,
        narrator: state.author.roles.narrator.audiobooks.page,
        publisher: state.author.roles.publisher.audiobooks.page,
      },
      comicbooks: {
        author: state.author.roles.author.comicbooks.page,
        illustrator: state.author.roles.illustrator.comicbooks.page,
        publisher: state.author.roles.publisher.comicbooks.page,
      },
      series: {
        author: state.author.roles.author.series.page,
        publisher: state.author.roles.publisher.series.page,
      },
    },
    locale: state.currentUser.data.locale,
    userId: state.currentUser.data.id,
  }
}

type RenderOptions = {
  role: string
  itemsType: 'list' | 'grid'
  listType?: 'default' | 'slider' | 'carousel' | 'walker' | string
  worksType: string
  limit?: number
  withSectionHeader?: boolean
  withMoreButton?: boolean
  coverHeight?: string
  showAllButton?: boolean
  sectionIndex?: number
  headerRank?: number
}

export function renderAuthorWorks(
  props: MappedAuthorProps,
  options: RenderOptions,
): JSX.Element | null {
  const { worksType } = options

  switch (worksType) {
    case 'books':
      return renderBooks({ props, options })
    case 'audiobooks':
      return renderAudiobooks({ props, options })
    case 'comicbooks':
      return renderComicbooks({ props, options })
    case 'series':
      return renderSeriesList({ props, options })
    default:
      return null
  }
}

function renderBooks({
  props,
  options,
}: {
  props: MappedAuthorProps
  options: RenderOptions
}) {
  if (!props.author) return null
  const { role, limit, itemsType, coverHeight, sectionIndex } = options
  let books = props.books[role]
  const {
    userId,
    author: { uuid },
    dispatch,
  } = props

  if (limit) {
    books = books.slice(0, limit)
  }

  const renderedBooks = books.map((book, i) => {
    return (
      <Book
        onClick={() =>
          sendOnClickAnalytics({
            listPosition: sectionIndex,
            dispatch,
            resourceType: BOOK,
            itemUuid: book.uuid,
            screenName: AUTHOR,
            listType: `${AUTHOR}_${role}`,
            position: i + 1,
            userId,
            resourceUuid: uuid,
          })
        }
        key={i}
        book={book}
        kind={itemsType}
        hoverable={false}
        isInSlider
        coverSize={HEIGHTS[coverHeight as string]}
      />
    )
  })

  return wrapInList(renderedBooks, props, options)
}

function renderAudiobooks({
  props,
  options,
}: {
  props: MappedAuthorProps
  options: RenderOptions
}) {
  if (!props.author) return null
  const { role, limit, itemsType, sectionIndex } = options
  let audiobooks = props.audiobooks[role]

  const {
    userId,
    author: { uuid },
    dispatch,
  } = props

  if (limit) {
    audiobooks = audiobooks.slice(0, limit)
  }

  const renderedAudiobooks = audiobooks.map((audiobook, i) => {
    return (
      <AudioBook
        onClick={() =>
          sendOnClickAnalytics({
            listPosition: sectionIndex,
            dispatch,
            resourceType: AUDIOBOOK,
            itemUuid: audiobook.uuid,
            screenName: AUTHOR,
            listType: `${AUTHOR}_${role}`,
            position: i + 1,
            userId,
            resourceUuid: uuid,
          })
        }
        audiobook={audiobook}
        kind={itemsType}
        hoverable={false}
      />
    )
  })

  return wrapInList(renderedAudiobooks, props, options)
}

function renderComicbooks({
  props,
  options,
}: {
  props: MappedAuthorProps
  options: RenderOptions
}) {
  if (!props.author) return null
  const { role, limit, itemsType, coverHeight, sectionIndex } = options
  let comicbooks = props.comicbooks[role]
  const {
    userId,
    author: { uuid },
    dispatch,
  } = props

  if (limit) {
    comicbooks = comicbooks.slice(0, limit)
  }

  const renderedComicbooks = comicbooks.map((comicbook, i) => {
    return (
      <Comicbook
        onClick={() =>
          sendOnClickAnalytics({
            listPosition: sectionIndex,
            dispatch,
            resourceType: COMICBOOK,
            itemUuid: comicbook.uuid,
            screenName: AUTHOR,
            listType: `${AUTHOR}_${role}`,
            position: i + 1,
            userId,
            resourceUuid: uuid,
          })
        }
        key={i}
        comicbook={comicbook}
        kind={itemsType}
        isInSlider
        coverSize={HEIGHTS[coverHeight as string]}
      />
    )
  })

  return wrapInList(renderedComicbooks, props, options)
}

function renderSeriesList({
  props,
  options,
}: {
  props: MappedAuthorProps
  options: RenderOptions
}) {
  if (!props.author) return null
  const { role, limit, itemsType, listType, sectionIndex } = options
  const {
    userId,
    author: { uuid },
    dispatch,
  } = props

  let seriesList = props.series[role]

  if (limit) {
    seriesList = seriesList.slice(0, limit)
  }
  const seriesType =
    listType === 'slider' && seriesList.length === 1 ? 'list' : itemsType // display as list if it only one series

  const renderedSeries = seriesList.map((series, i) => {
    return (
      <Series
        onClick={() =>
          sendOnClickAnalytics({
            listPosition: sectionIndex,
            dispatch,
            resourceType: SERIES,
            itemUuid: series.uuid,
            screenName: AUTHOR,
            listType: `${AUTHOR}_${role}`,
            position: i + 1,
            userId,
            resourceUuid: uuid,
          })
        }
        key={i}
        series={series}
        kind={seriesType}
      />
    )
  })

  return wrapInList(renderedSeries, props, options)
}

function wrapInList(
  elements: React.ReactNode[],
  props: MappedAuthorProps,
  options: RenderOptions,
) {
  const {
    authorMeta: { uuid: authorId },
  } = props
  const {
    role,
    worksType,
    withSectionHeader,
    listType,
    coverHeight,
    showAllButton,
    headerRank,
  } = options
  const listProps: ListPropsType = {}

  if (headerRank) listProps.headerRank = headerRank
  if (withSectionHeader) {
    listProps.header = getSectionId(role, worksType)
  }

  if (shouldShowMoreButton(props, options)) {
    listProps.allLink = urlFor.authorWorks({
      authorId,
      role: role as AuthorRole,
      worksType: worksType as AuthorWorkTypes,
      query: props.app.storedQuery,
    })
  }

  if (listType) {
    listProps.type = elements.length > 5 ? (listType as ListType) : 'slider'
  }

  if (coverHeight) {
    listProps.coverHeight = coverHeight
  }

  if (showAllButton) {
    listProps.showAllButton = showAllButton
  }

  return (
    <List kind="index" {...listProps}>
      {elements}
    </List>
  )
}

function shouldShowMoreButton(
  props: MappedAuthorProps,
  options: RenderOptions,
) {
  const { role, worksType, withMoreButton, limit } = options
  const { ids, total_count } = props.authorMeta.roles[role][worksType]
  const isLimitLessThanTotalCount = Boolean(limit && limit < total_count)

  return (
    withMoreButton && (isLimitLessThanTotalCount || ids.length < total_count)
  )
}

export function getSecondaryAuthors(
  authors: MinimalAuthorInfo[],
  query?: QueryParams,
): JSX.Element[] | null {
  if (!authors || !authors.length) return null

  return authors.map<JSX.Element>((author, index) => {
    return index !== authors.length - 1 ? (
      <span key={index}>
        <Linka path={urlFor.author(author.uuid, query)}>{author.name}</Linka>,{' '}
      </span>
    ) : (
      <Linka key={index} path={urlFor.author(author.uuid, query)}>
        {author.name}
      </Linka>
    )
  })
}

export type ListPropsType = {
  type?: ListType
  header?: string
  allLink?: string
  coverHeight?: string
  showAllButton?: boolean
  headerRank?: number
}

export function getSectionId(role: string, worksType: string): string {
  return `author.${ListTitleIds[role][worksType]}`
}

type ListType = 'default' | 'slider' | 'carousel'

export function renderMediaLinks({
  external_links,
  listType = 'default',
  linkKind,
  authorUuid,
  withMoreButton,
  withHeader = true,
  sectionIndex,
  userId,
  dispatch,
  query,
}: {
  external_links: ExternalLinkType[]
  listType?: ListType
  linkKind: string
  authorUuid: string
  withMoreButton?: boolean
  withHeader?: boolean
  sectionIndex: number
  userId: number
  dispatch: Dispatch
  query?: QueryParams
}): JSX.Element {
  const listProps = {
    type: listType,
    header: withHeader ? 'author.media_links' : '',
    allLink: '',
    headerRank: 2,
  }

  if (withMoreButton) {
    listProps.allLink = urlFor.authorMediaLinks(authorUuid, query)
  }

  return (
    <List kind="index" {...listProps}>
      {external_links.map((link, index) => (
        <ExternalLink
          onClick={() =>
            sendOnClickAnalytics({
              listPosition: sectionIndex,
              dispatch,
              resourceType: EXTERNAL_LINK,
              itemUuid: link.url,
              screenName: AUTHOR,
              listType: `${AUTHOR}_${AUTHOR}`,
              position: index + 1,
              userId,
              resourceUuid: authorUuid,
            })
          }
          key={index}
          externalLink={link}
          kind={linkKind}
        />
      ))}
    </List>
  )
}

const titlesExtractor = compose(
  uniq,
  map(property('title')),
  compact,
  flattenDeep,
  map(values),
  values,
  pick(['books', 'audiobooks', 'comicbooks', 'series']),
)

export function getAllTitles(props: MappedAuthorProps) {
  return titlesExtractor(props)
}
