import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import cn from 'classnames'

import './users-list.styl'

import urlFor, { QueryParams } from 'shared/tools/url-helper'
import Linka from 'client/shared/blocks/linka'
import { UserAvatar } from 'client/shared/blocks/user-avatar'
import { UserShort } from 'client/shared/types/user'

type Props = {
  users: UserShort[]
  message: string
  usersCount: number
  isUserInfoPage?: boolean
  query?: QueryParams
  allFriends?: boolean
}

export default class UsersList extends Component<Props> {
  render(): JSX.Element | null {
    const {
      users,
      allFriends,
      usersCount,
      message,
      isUserInfoPage,
      query,
    } = this.props
    const SHOW_FRIENDS_NAMES_NUMBER = isUserInfoPage ? 2 : 1
    const SHOW_FRIENDS_AVATARS_NUMBER = isUserInfoPage ? 3 : 2

    const friends = allFriends ? users : users?.filter(user => user.following)
    const friendsNames = friends.slice(0, SHOW_FRIENDS_NAMES_NUMBER)
    const restCount = usersCount - friendsNames.length

    if (!friends.length) return null

    return (
      <div className="users-list">
        <div
          className={cn({
            'users-list__avatars_user-page': isUserInfoPage,
          })}
        >
          {/* eslint-disable-next-line array-callback-return */}
          {friends.map((friend, i) => {
            if (i < SHOW_FRIENDS_AVATARS_NUMBER) {
              return (
                <Linka
                  path={urlFor.user(friend.login, query)}
                  className="users-list__user"
                  key={i}
                >
                  <UserAvatar user={friend} size={isUserInfoPage ? 40 : 30} />
                </Linka>
              )
            }
          })}
        </div>
        {friends.length > 0 && (
          <div
            className={cn('users-list__text', {
              'users-list__text_user-page': isUserInfoPage,
              'users-list__text_resource-page': !isUserInfoPage,
            })}
          >
            <span
              className={cn('users-list__message', {
                'users-list__message_resource-page': !isUserInfoPage,
              })}
            >
              <Trans id={message} />
            </span>
            {friendsNames.map((friend, i) => {
              return (
                <Linka
                  path={urlFor.user(friend.login, query)}
                  className="users-list__name"
                  key={i}
                >
                  {friend.name || friend.login}
                </Linka>
              )
            })}
            {restCount > 0 && (
              <span className="users-list__rest">
                <Trans id="book.reading_rest" values={{ count: restCount }} />
              </span>
            )}
          </div>
        )}
      </div>
    )
  }
}
