import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push, replace } from 'react-router-redux'
import { withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'

import errorBoundary from 'client/shared/decorators/error-boundary'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import { trimText } from 'client/shared/helpers/text-helpers'
import { focusCommentForm } from 'client/shared/helpers/dom-helpers'

import SimpleFormat from 'client/shared/blocks/simple-format'
import { Book } from 'client/shared/blocks/book'
import { Card, CardKind } from 'client/bookmate/blocks/card'
import Quote from 'client/bookmate/blocks/quote'

import {
  throttledToggleLike as likeQuote,
  remove as removeQuote,
} from 'client/bookmate/reducers/quotes-reducer'
import { loadSerial } from 'client/bookmate/reducers/serial-reducer'
import { getBookById } from 'client/bookmate/selectors/book-selector'

import { report as reportProblem } from 'client/bookmate/reducers/problem-form-reducer'
import { show as showAuthPopup } from 'client/shared/reducers/popup-reducer'

import DeleteIcon from 'client/shared/icons/delete.svg'
import ReportIcon from 'client/shared/icons/exclam.svg'

import { QuoteProps } from 'client/shared/types/quote'
import { CurrentUserState } from 'client/shared/types/current-user'
import { Dispatch, State } from 'shared/types/redux'
import { MenuDataItem } from 'client/shared/blocks/context-menu'
import { SerialProps } from 'client/shared/types/serial'
import { BookSerialProps } from 'client/shared/types/resource'
import { changeQuoteLikesCount } from '../reducers/author-reducer'
import isEmpty from 'lodash/isEmpty'

type Props = withI18nProps & {
  quote: QuoteProps
  as?: string
  embeddedBook?: BookSerialProps
  kind?: 'default' | 'page'
  deletable: boolean
  extraHeader?: React.ReactNode
  menuItems?: MenuDataItem[]
  currentUser: CurrentUserState
  dispatch: Dispatch
  serial: SerialProps
  domain: string
  entryType?: string
  isAuthorPage?: boolean
  query?: QueryParams
}

const QUOTE_MAX_LENGTH = 90

class _CardQuoteBox extends Component<Props> {
  static defaultProps = {
    deletable: true,
  }

  componentDidMount(): void {
    const { embeddedBook, serial, dispatch } = this.props
    if (
      embeddedBook &&
      embeddedBook.source_type === 'serial_episode' &&
      !serial.uuid
    ) {
      dispatch(loadSerial(embeddedBook.parent_uuid))
    }
  }

  getTextSize(): string {
    const { kind, quote } = this.props

    if (kind === 'page' && quote.content.length < 200) {
      return 'font-size-xx-large'
    } else {
      return 'default-size'
    }
  }

  render(): JSX.Element {
    const {
      quote,
      embeddedBook,
      extraHeader,
      kind,
      dispatch,
      currentUser: { auth },
      serial,
      query,
      as,
    } = this.props

    const header = {
      user: quote.creator,
      actionMessage: 'card.shared_quote',
      actionValues: {
        gender: quote.creator.gender,
      },
      date: quote.created_at * 1000,
      path: urlFor.bookQuote({ quoteUuid: quote.uuid, query }),
      showAuthPopup: arg => dispatch(showAuthPopup(arg)),
      isLoggedIn: auth,
    }

    const footer = {
      likeCount: quote.likes_count,
      commentCount: quote.comments_count,
      liked: quote.liked,
      addToShelfLink: urlFor.quoteAddToShelf(quote.uuid, query),
      shareData: this.shareData,
      menuData: this.getMenuData(),
      analytics: {
        object_type: 'quote',
        object_id: quote.uuid,
      },
      showAuthPopup: () => dispatch(showAuthPopup()),
      isLoggedIn: auth,
    }

    const book =
      embeddedBook && embeddedBook.source_type === 'serial_episode'
        ? isEmpty(serial)
          ? quote.serial
          : serial
        : embeddedBook

    return (
      <Card
        kind={kind as CardKind}
        header={header}
        footer={footer}
        extraHeader={extraHeader}
        onLikeClick={this.toggleLike}
        onCommentClick={this.openComments}
        as={as}
      >
        <Quote>
          <SimpleFormat text={quote.content} />
        </Quote>
        {quote.comment && <SimpleFormat text={quote.comment} element="p" />}
        {book && <Book book={book} kind="list" />}
      </Card>
    )
  }

  toggleLike = (): void => {
    const {
      currentUser: { auth },
      quote: { uuid, liked, likes_count },
      dispatch,
      isAuthorPage,
    } = this.props

    if (!auth) {
      return dispatch(
        showAuthPopup({
          callback: () => likeQuote({ uuid, liked, likes_count, dispatch }),
        }),
      )
    }

    likeQuote({ uuid, liked, likes_count, dispatch })
    if (isAuthorPage) {
      dispatch(
        changeQuoteLikesCount({
          uuid,
          liked,
          likes_count,
          dispatch,
        }),
      )
    }
  }

  isMine(): boolean {
    const {
      currentUser: { data: currentUser },
      quote,
    } = this.props

    return quote.creator && quote.creator.login === currentUser.login
  }

  getMenuData() {
    const { deletable, i18n, menuItems: menuItemsFromParent } = this.props
    const position = 'top-right'

    if (menuItemsFromParent) {
      return {
        position,
        items: menuItemsFromParent,
      }
    } else if (this.isMine() && deletable) {
      return {
        position,
        items: [
          {
            icon: DeleteIcon,
            title: i18n.t`shared.remove`,
            action: this.onRemoveClick,
          },
        ],
      }
    } else if (!this.isMine()) {
      return {
        position,
        items: [
          {
            icon: ReportIcon,
            title: i18n.t`context-menu.complain`,
            action: this.onComplainClick,
          },
        ],
      }
    }
  }

  get shareData() {
    const {
      i18n,
      quote,
      currentUser: {
        data: { locale },
      },
      domain,
    } = this.props
    const url = `${urlFor.absoluteRootWithSubdomain(
      locale,
      domain,
    )}${urlFor.bookQuote({ quoteUuid: quote.uuid })}`

    return {
      facebook: { url },
      twitter: {
        url,
        title: i18n._('card.quote_twitter_share', {
          quote: trimText(quote.content, QUOTE_MAX_LENGTH),
        }),
      },
    }
  }

  onRemoveClick = (): void => {
    const {
      quote: {
        uuid,
        book: { uuid: bookUuid },
      },
      kind,
      i18n,
      dispatch,
    } = this.props

    if (confirm(i18n.t`context-menu.quote_remove_confirm`)) {
      const removeQuoteArgs: [uuid: string, bookUuid: string] = [uuid, bookUuid]
      if (kind === 'page') {
        removeQuoteArgs.push((this.redirectToBookPage as unknown) as string)
      }
      dispatch(removeQuote.apply(this, removeQuoteArgs))
    }
  }

  onComplainClick = () => {
    const {
      quote: { uuid },
      dispatch,
    } = this.props
    dispatch(
      reportProblem({
        content_problem: {
          problem_kind: 'complaint',
          problem_type: 'text',
          resource_type: 'm',
          resource_uuid: uuid,
        },
      }),
    )
  }

  redirectToBookPage = (): void => {
    const {
      quote: {
        book: { uuid: bookUuid, source_type, parent_uuid },
      },
      query,
      dispatch,
    } = this.props

    if (source_type === 'serial_episode') {
      dispatch(replace(urlFor.serial(parent_uuid, query)))
    } else {
      dispatch(replace(urlFor.book(bookUuid, query)))
    }
  }

  openComments = () => {
    const {
      currentUser: { auth },
      dispatch,
    } = this.props

    if (!auth) {
      return dispatch(
        showAuthPopup({
          callback: this.onCommentsClick,
        }),
      )
    }

    this.onCommentsClick()
  }

  onCommentsClick = (): void => {
    const { quote, dispatch, query } = this.props

    dispatch(push(urlFor.bookQuote({ quoteUuid: quote.uuid, query })))

    focusCommentForm()
  }
}

const wrappers = compose(
  connect((state: State, ownProps: { embeddedBook: any }) => {
    const embeddedBook = ownProps.embeddedBook
    const isSerialLoad =
      embeddedBook &&
      state.serial.uuid &&
      state.serial.uuid === embeddedBook.parent_uuid

    return {
      currentUser: state.currentUser,
      serial: isSerialLoad ? getBookById(state, embeddedBook.parent_uuid) : {},
      domain: state.app.domain,
      query: state.app.storedQuery,
    }
  }),
  errorBoundary,
  withI18n({ update: true }),
)

export const CardQuoteBox = wrappers(_CardQuoteBox)
