import React, { Component } from 'react'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import './author-language-variant.styl'

import urlFor, { QueryParams } from 'shared/tools/url-helper'
import { push } from 'react-router-redux'

import Linka from 'client/shared/blocks/linka'
import Badge from 'client/shared/blocks/badge'
import MetaCounters from 'client/bookmate/blocks/meta-counters'

import { AuthorLanguageVariant as AuthorLanguageVariantType } from 'client/bookmate/reducers/author-reducer'

type Props = {
  kind: 'default' | 'dropdown-content'
  languageVariant: AuthorLanguageVariantType
  query?: QueryParams
} & DecoratorProps

class AuthorLanguageVariant extends Component<Props> {
  static defaultProps = {
    kind: 'default',
  }

  render(): JSX.Element | null {
    if (this.props.kind === 'dropdown-content') {
      return this.renderDropdownContent()
    } else {
      return this.renderDefault()
    }
  }

  renderDefault(): JSX.Element | null {
    const {
      dispatch,
      query,
      languageVariant: {
        name,
        locale: language,
        uuid,
        works_count: worksCount,
      },
    } = this.props

    if (!dispatch) return null

    return (
      <Linka
        className="author-language-variant author-language-variant_default"
        title={name}
        path={urlFor.author(uuid, query)}
        pseudo
        onClick={() => {
          return dispatch(push(urlFor.author(uuid, query)))
        }}
      >
        <div className="author-language-variant__language">
          <Badge type="language" query={query}>
            {language}
          </Badge>
        </div>
        <div className="author-language-variant__info">
          <span className="author-language-variant__author-name">{name}</span>
          {worksCount > 0 && (
            <MetaCounters counters={[{ kind: 'books', count: worksCount }]} />
          )}
        </div>
      </Linka>
    )
  }

  renderDropdownContent(): JSX.Element | null {
    const {
      dispatch,
      query,
      languageVariant: {
        name,
        locale: language,
        uuid,
        works_count: worksCount,
      },
    } = this.props

    if (!dispatch) return null

    return (
      <Linka
        className="author-language-variant author-language-variant_dropdown-content"
        title={name}
        path={urlFor.author(uuid, query)}
        pseudo
        onClick={() => dispatch(push(urlFor.author(uuid, query)))}
      >
        <span className="author-language-variant__author-name">{name}</span>
        <div className="author-language-variant__info">
          <span className="author-language-variant__language">
            <Badge type="language">{language}</Badge>
          </span>
          {worksCount > 0 && (
            <MetaCounters counters={[{ kind: 'books', count: worksCount }]} />
          )}
        </div>
      </Linka>
    )
  }
}

export default uiBox(AuthorLanguageVariant)
