import React, { Component } from 'react'
import noop from 'lodash/noop'

import './emotion.styl'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import deviceHelper from 'shared/tools/device-helper'

import { EmotionProps } from 'client/shared/types/emotion'

type Props = DecoratorProps &
  EmotionProps & {
    selected: boolean
    selectable: boolean
    onClick: (
      e: React.SyntheticEvent<HTMLDivElement>,
      arg1: string,
      arg2: boolean,
    ) => void
  }

class Emotion extends Component<Props> {
  static defaultProps = {
    selected: false,
    selectable: false,
    onClick: noop,
  }

  render(): JSX.Element {
    const {
      app,
      unicode,
      image_url,
      label,
      selectable,
      selected,
      color,
    } = this.props
    const selectableMod = selectable ? 'emotion_selectable' : ''
    const selectedMod = selected ? 'emotion_selected' : ''

    const windowsEmoji = (
      <span className="emotion__icon">
        <img src={image_url} width="24" height="24" alt="" />
      </span>
    )
    const defaultEmoji = <span className="emotion__icon">{unicode}</span>

    return (
      <div
        className={`emotion ${selectableMod} ${selectedMod}`}
        onClick={this.onClick}
        style={{ backgroundColor: selected ? `#${color.toString(16)}` : '' }}
      >
        {deviceHelper.isWindows(app) || deviceHelper.isWinPhone(app)
          ? windowsEmoji
          : defaultEmoji}
        {label && <span className="emotion__label">{label}</span>}
      </div>
    )
  }

  onClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const { onClick, name, selected } = this.props

    onClick(e, name, !selected)
  }
}

export default uiBox(Emotion)
