import React, { Component } from 'react'
import cn from 'classnames'

import './tab.styl'

import Linka from 'client/shared/blocks/linka'

type Props = {
  path: string
  useHistoryReplaceState: boolean
  count?: number
  disableCount?: boolean
  disableLoginWall?: boolean
  kind?: string | null | undefined
  children: React.ReactNode
  active: boolean
  pseudo?: boolean
  onClick?: () => void | Promise<void>
  onlyActiveOnIndex?: boolean
  className?: string
  hideActiveClass?: boolean
}

export class Tab extends Component<Props> {
  static defaultProps = {
    disableCount: false,
    useHistoryReplaceState: false,
    active: false,
  }

  shouldShowCount(): boolean {
    const { count, disableCount } = this.props
    return typeof count === 'number' && !disableCount
  }

  render(): JSX.Element {
    const {
      path,
      kind,
      count,
      disableLoginWall,
      useHistoryReplaceState,
      active,
      pseudo,
      onClick,
      hideActiveClass,
      onlyActiveOnIndex,
    } = this.props

    return (
      <Linka
        onClick={onClick}
        path={path}
        className={cn('tab', kind && `tab_${kind}`, {
          tab_active: active,
        })}
        activeClassName={hideActiveClass ? '' : 'tab_active'}
        replace={useHistoryReplaceState}
        loginWall={!disableLoginWall}
        pseudo={pseudo}
        onlyActiveOnIndex={onlyActiveOnIndex}
      >
        <span className="tab__content">{this.props.children}</span>
        {this.shouldShowCount() && <span className="tab__count">{count}</span>}
      </Linka>
    )
  }
}
