import { getReducedNumber } from 'client/shared/helpers/text-helpers'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'
import { QueryParams } from 'shared/tools/url-helper'

export function getTabType(tabLink: string): string | undefined {
  return tabLink?.split('/').pop()
}

export function aboutTabHelper(currentPage: string, uiid: string): boolean {
  return getTabType(currentPage) === uiid
}

export function impressionsTabHelper(currentLocationHref: string): boolean {
  return getTabType(currentLocationHref) === 'impressions'
}

export function checkIfTabExists(count: number): boolean {
  return count > 0
}

function checkIfShouldReduceCount(count: number) {
  return count ? getReducedNumber(count) : 0
}

function generateTab({
  resourceType,
  uuid,
  tab,
  query,
}: {
  resourceType: string
  uuid: string
  tab: Tab
  query?: QueryParams
}) {
  const { tabType, tabTitle, count, pseudo } = tab

  let link = !tabType
    ? `/${resourceType}s/${uuid}`
    : `/${resourceType}s/${uuid}/${tabType}`

  if (query && !isEmpty(query) && !pseudo) {
    link = `${link}?${qs.stringify(query)}`
  }

  const TABS_WITH_COUNT = [
    'impressions',
    'quotes',
    'readers',
    'listeners',
    'episodes',
  ]
  const hasItemsCount = TABS_WITH_COUNT.includes(tabType as string)
    ? checkIfShouldReduceCount(Number(count))
    : ''

  return {
    title: tabTitle,
    link,
    hasItemsCount,
    active: false,
    pseudo: Boolean(pseudo),
  }
}

export type Tab = {
  tabType?: string
  tabTitle: string
  count: number | boolean
  pseudo?: boolean
}

export type PreparedTab = {
  title: string
  link: string
  hasItemsCount: string | number
  active: boolean
  pseudo: boolean
}

export function generateTabs({
  resourceType,
  uuid,
  tabs,
  query,
}: {
  resourceType: string
  uuid: string
  tabs: Tab[]
  query?: QueryParams
}): PreparedTab[] {
  return tabs.map(tab => generateTab({ resourceType, uuid, tab, query }))
}

export const objectHasAnyPositiveProperty = (
  object: Record<string, any>,
): boolean => {
  for (const item in object) {
    if (object[item].length > 0) return true
  }
  return false
}
